import {
  ComponentProps,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  SelectHTMLAttributes,
  useEffect,
  useRef,
} from 'react';
import { useField, useFormikContext } from 'formik';

import { track } from 'utils/analytics';
import Label from 'humanity/primitives/label';
import Input from 'humanity/primitives/input';
import FormGroup from 'humanity/components/formGroup';
import InputError from 'humanity/components/inputError';
import PhoneInput from 'humanity/components/phoneInput';
import Select from 'humanity/primitives/select';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'defaultValue'> & {
  type: 'text' | 'number' | 'email' | 'tel' | 'date' | 'select' | 'radio';
  label: string;
  options?: ComponentProps<typeof Select>['options'];
  name: string;
  inputId: string;
  variant?: 'dark' | 'light';
  inputWidth?: 'full' | 'half' | 'third';
  autocompleteValue?: string;
  required?: boolean;
  analyticsData?: Record<string, unknown>;
  defaultValue?: ComponentProps<typeof Input>['defaultValue'];
};

const FormikInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      type,
      label,
      options,
      name,
      inputId,
      autocompleteValue,
      inputWidth = 'full',
      variant = 'light',
      required = false,
      analyticsData = {},
      defaultValue = null,
      ...props
    },
    ref
  ) => {
    const [field, meta] = useField({ name, type, ...props });
    const { setFieldValue } = useFormikContext();
    const hasRun = useRef<boolean | null>(null);

    useEffect(() => {
      if (!hasRun.current && defaultValue && field.value === '') {
        setFieldValue(name, defaultValue.value);
        hasRun.current = true;
      }
    }, [defaultValue, setFieldValue, field.value, name]);

    const handleBlur: FocusEventHandler = (e) => {
      field.onBlur(e);

      track('form_interaction', {
        form: {
          type,
          name,
          id: inputId,
          input: meta.value,
        },
        ...analyticsData,
      });
    };

    return (
      <FormGroup data-testid="FormikInputGroup" span={inputWidth}>
        <Label
          data-testid="FormikInputLabel"
          htmlFor={inputId}
          required={required}
          color={variant === 'dark' ? 'white' : 'blue100'}
        >
          {label}
        </Label>
        {type === 'tel' && (
          <PhoneInput
            {...field}
            data-testid="FormikPhoneInput"
            ref={ref}
            id={inputId}
            name={name}
            autoComplete="tel"
            onBlur={handleBlur}
            hasError={Boolean(meta.touched && meta.error)}
            {...props}
          />
        )}
        {type === 'select' && (
          <Select
            {...field}
            data-testid="FormikSelect"
            ref={ref as ForwardedRef<HTMLSelectElement>}
            options={options ?? []}
            inputId={inputId}
            name={name}
            autoComplete={autocompleteValue}
            onBlur={handleBlur}
            hasError={Boolean(meta.touched && meta.error)}
            {...(props as SelectHTMLAttributes<HTMLSelectElement>)}
          />
        )}
        {type !== 'tel' && type !== 'select' && (
          <Input
            {...field}
            data-testid="FormikInput"
            ref={ref}
            type={type}
            id={inputId}
            name={name}
            autoComplete={autocompleteValue}
            onBlur={handleBlur}
            hasError={Boolean(meta.touched && meta.error)}
            {...props}
          />
        )}
        {meta.touched && meta.error && <InputError>{meta.error}</InputError>}
      </FormGroup>
    );
  }
);

FormikInput.displayName = 'FormikInput';

export default FormikInput;
