import { ComponentProps, forwardRef, LabelHTMLAttributes, ReactNode } from 'react';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import css from '@styled-system/css';

import Box from 'humanity/primitives/box';

const RedText = styled.span(
  css({
    color: 'redError',
  })
);

type Props = LabelHTMLAttributes<HTMLLabelElement> & {
  required?: boolean;
  children?: ReactNode;
};

const Label = forwardRef<HTMLLabelElement, Props>(
  ({ required = false, children = '', ...props }, ref) => {
    const LabelBox = Box as StyledComponent<
      'label',
      DefaultTheme,
      ComponentProps<typeof Box>
    >;
    return (
      <LabelBox
        as="label"
        ref={ref}
        sx={{
          ml: 1,
          fontSize: 'label',
          color: 'blue100',
          letterSpacing: '0.01em',
        }}
        {...props}
      >
        {children}&nbsp;{required && <RedText>*</RedText>}
      </LabelBox>
    );
  }
);

Label.displayName = 'Label';

export default Label;
